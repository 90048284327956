<div class="footer-container mat-typography" fxLayout="row" fxLayoutAlign="center start">

  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.5rem">
    <img src="assets/images/logos/logo_infrastructure_c.png" alt="Platform logo" />
    <span>Natural and Infrastructure Risk Manager</span>
  </div>
  <div fxFlex></div>
  <div fxLayout="row" fxLayoutGap="1.5rem">
    <!-- <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="0.5rem">
      <span>{{ 'funded by' | translate }}: </span>
      <img src="assets/images/red_es.png" (click)="openRedES()" alt="iPresas RedES" />
    </div> -->
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="0.5rem">
      <img src="assets/images/logo_white.png" (click)="openWebsite()" alt="iPresas Website" />
      <span>© All rights reserved</span>
    </div>
  </div>
</div>
